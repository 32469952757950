// Define FVH color palette to override default Bootstrap colors:

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
// $gray-900: #212529;
// $black:    #000;
$gray-900: #1b2c41;
$black: #1b2c41;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #d70074;
$orange: #FF5000;
// $yellow:  #Ffd650;
$yellow: #f9b122;
$green: #28a745;
$teal: #009E92;
$cyan: #00a4cc;

// stylelint-disable-next-line scss/dollar-variable-default
$colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800
);

// $primary:       $orange;
// $secondary:     $teal;
$primary: $yellow;
$secondary: $yellow;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $black;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: (
        "primary": $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

@import "../node_modules/bootstrap/scss/bootstrap";


// Misc style corrections for our use cases:
a, button {
  background: none;
  border: none;
  cursor: pointer;
}

button.nav-link {
  display: inline-block;
}

button.nav-link:focus {
  outline: none;
}

.container {
  max-width: 800px;
}

.clickable {
  cursor: pointer;
}

body {
  background-image: url("/images/Blue_road_Jussi_Hellsten.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.trans-bg {
  background: linear-gradient(rgba(255, 255, 255, .95), rgba(255, 255, 255, .95));
}

.fill-danger {
  fill: $danger;
}

.chart-caption {
  font-size: 28px;
}

.z-1 {
  position: relative;
  z-index: 100;
}

.btn-trans {
  opacity: 0.3
}

.btn-trans:hover {
  opacity: 1
}

.font-size-base {
  font-size: $font-size-base;
}

.org-shape {
  transition: opacity 1s;
}